// register scrolltrigger and split text plugins
gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother)

const select = (e) => document.querySelector(e)
const selectAll = (e) => document.querySelectorAll(e)
const selectId = (id) => document.getElementById(id)
const vh = (coef) => window.innerHeight * (coef/100)
const vw = (coef) => window.innerWidth * (coef/100)

// scroll smoother
let smoother

// change the fs menu active item according to the url
function updateMenu() {
	$('#fs-menu .menu a').removeClass('active')

	setTimeout(function(){

		var currentUrl = window.location.href
		$("link[rel='canonical']").attr('href', currentUrl)

		if ($('#main-content').attr('class').includes('about')) {
			$('#fs-menu .menu li:nth-child(2) a').addClass('active')
		}

		else if ($('#main-content').attr('class').includes('projects')) {
			$('#fs-menu .menu li:nth-child(3) a').addClass('active')
		}

		else if ($('#main-content').attr('class').includes('project-inner')) {
			$('#fs-menu .menu li:nth-child(3) a').addClass('active')
		}
	
		else if ($('#main-content').attr('class').includes('contact')) {
			$('#fs-menu .menu li:last-child a').addClass('active')
		}

		else {
			$('#fs-menu .menu li:first-child a').addClass('active')
		}

	}, 50)
}

// init lazyload
function initLazyLoad() {
	const lazyLoadInstance = new LazyLoad({ 
		elements_selector: '.lazy',
		container: select('.main-wrap')
	})

	// refresh scrolltrigger once an image is loaded
	gsap.utils.toArray('.lazy').forEach(item => {
		item.addEventListener('load', () => {
			if (!item.classList.contains('cover')) {
				ScrollTrigger.refresh()
			}
		})
	})
}

// validate all the forms on the page that has the class '.from-validate'
function validateForms() {
	if(select('.form-validate')) {

		const form = $('.form-validate')

		$.each(form, function() {
			
			window.form_validator = $('.form-validate').validate({
				errorPlacement: function(error, element) {
					error.appendTo(element.closest('.form-line'))
					error.addClass('error-msg')
				},
				highlight: function (element){
					$(element).closest('.form-line').addClass('error')
				},
				unhighlight: function (element){
					$(element).closest('.form-line').removeClass('error')
				},
				messages: {
					name: 'Required',
					email: 'Invalid Email',
					message: 'Required',
				}
			})

			let valid = true

			$('#contact-send').click(function(e) {
				e.preventDefault()
				valid = window.form_validator.form()
				//console.log(valid)
				
				if(valid){
					var form =  $('.form-validate')
					//console.log(form)
					var request
		
					if (request) {
						request.abort()
					}
					
					var serializedData = form.serialize()	
		
					$.ajax({
						type: 'POST',
						//url: location.origin + '/clients/skyline-2023/assets/php/mailer.php', //localhost
						url: location.origin + '/assets/php/mailer.php', //prod
						data: serializedData,
						success: function(result) {
							//console.log(result)
							if(result == '1'){
								return
							}
							
							$('body').addClass('popup-open')
							$('#overlay').fadeIn()

							// gtag event
							gtag('event', 'submission', {
								'event_category': 'Form',
								'event_label': 'Contact Form Submission',
								'value': 1
							})

							const overlay = gsap.timeline()

							overlay.from('#overlay .content', {
								scale: 0,
								ease: 'back.out(1.2)',
								duration: .6,
								onComplete: () => {
									form.trigger('reset')
								}
							})

							overlay.fromTo('#overlay .content .outline', {
								scale: 1,
								autoAlpha: 1
							}, {
								scale: 2,
								autoAlpha: 0,
								ease: 'power2.out',
								duration: 1.5
							}, '-=.5')
						},
						error: function(result) {
							console.log(result)
						}
					})
				}
		
			})

			$('#overlay .close-popup, #overlay .bg-wrapper').click(function(){
				$('body').removeClass('popup-open')
				$('#overlay').fadeOut()
			})
		})
	}
}

// init all the effects on the homepage banner (three.js, etc)
function initHomeBanner() {
	document.fonts.ready.then(() => {
		if (selectId('home-banner')) {

			// create the loop on the banner words
			const words = ['Feelings', 'Journeys', 'Reactions', 'Emotions'];
			const textContainer = $('#home-banner .text-02 .words');
			let currentIndex = 0;

			function animateText() {
				const currentWord = words[currentIndex];
				const nextIndex = (currentIndex + 1) % words.length;
				const nextWord = words[nextIndex];

				const splitText = new SplitText(textContainer, {
					type: 'lines, words, chars',
					linesClass: 'split-line'
				});

				const tl = gsap.timeline();

				tl.set(textContainer, {
					opacity: 1
				})

				tl.fromTo(splitText.chars, {
					y: 100 + '%'
				}, {
					y: 0,
					duration: 0.5, 
					ease: 'back.out(1.7)',
					stagger: 0.05,
				})

				.to(splitText.chars, {
					duration: .5,
					ease: 'circ.out',
					stagger: 0.05,
					opacity: 0
				}, 3)
				
				.set(textContainer, {
					textContent: nextWord,
					opacity: 0
				})

				currentIndex = nextIndex;

				gsap.delayedCall(4, animateText);
			}
			
			animateText();

			// create the scene
			const scene = new THREE.Scene();

			// create a renderer
			const renderer = new THREE.WebGLRenderer({
				antialias: true,
				alpha: true,
				powerPreference: 'high-performance',
				preserveDrawingBuffer: false
			})

			renderer.setSize( window.innerWidth, window.innerHeight + 2 );
			renderer.setPixelRatio( window.devicePixelRatio * 1 )
			selectId('three-js').appendChild(renderer.domElement)

			// load the 3d model
			const loader = new THREE.GLTFLoader();

			loader.load('assets/3d/letter-s/scene.gltf', function ( gltf ) {
				
				const object = gltf.scene;

				// generate the cube for the reflections
				const cubeTextureLoader = new THREE.CubeTextureLoader();
				cubeTextureLoader.setPath( 'assets/3d/letter-s/textures/' );
				
				const envMap = cubeTextureLoader.load([
					'px.jpg',
					'nx.jpg',
					'py.jpg',
					'ny.jpg',
					'pz.jpg',
					'nz.jpg'
				]);

				envMap.mapping = THREE.CubeReflectionMapping;

				// Access the mesh in the loaded object
				const mesh = object.children[0];

				// Create a new material
				const material = new THREE.MeshStandardMaterial({
					color: 0x111111,
					envMap: envMap,
					envMapIntensity: 1,
					metalness: 1,
					roughness: 0,
					side: THREE.FrontSide,
				});

				// Assign the material to the mesh
				mesh.material = material;

				// Set the initial position and scale of the loaded object
				mesh.position.set(0, 0, 0);
				mesh.rotation.set(0, 0, -.1);

				// create the camera
				const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
				if ($(window).width() > 993) {
					camera.position.set(0, 0, 2.4);
				} else {
					camera.position.set(1.45, -.2, 2.75);
				}

				// set the lights
				light = new THREE.PointLight(0x29c5fb, 5000, 2500);
				light.position.set(-1000, 2000, 0);
				scene.add(light);

				light2 = new THREE.PointLight(0xcc8899, 100, 3000);
				light2.position.set(1000, -500, 100);
				scene.add(light2);

				// add particles
				var particle;
				particle = new THREE.Object3D();

				if ($(window).width() > 993) {
					var geometry = new THREE.TetrahedronGeometry(.5, 2);
				} else {
					var geometry = new THREE.TetrahedronGeometry(.75, 1);
				}

				var particleMaterial = new THREE.MeshPhongMaterial({
					color: 0xf8f8f8,
				});

				if ($(window).width() > 993) {
					for (var i = 0; i < 1000; i++) {
						var particleMesh = new THREE.Mesh(geometry, particleMaterial);
						particleMesh.position.set(Math.random() - .5, Math.random() - .5, Math.random() - .5).normalize();
						particleMesh.position.multiplyScalar(90 + (Math.random() * 1000));
						particleMesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
						particle.add(particleMesh);
					}
				} else {
					for (var i = 0; i < 500; i++) {
						var particleMesh = new THREE.Mesh(geometry, particleMaterial);
						particleMesh.position.set(Math.random() - .5, Math.random() - .5, Math.random() - .5).normalize();
						particleMesh.position.multiplyScalar(90 + (Math.random() * 500));
						particleMesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
						particle.add(particleMesh);
					}
				}

				scene.add(particle);

				// Create an empty group as the parent of the loaded object
				var pivot = new THREE.Group();
				scene.add(pivot);
				pivot.add(object);

				// Adjust the position of the pivot point
				pivot.position.set(1.3, -.15, -.1);

				if ($(window).width() > 993) {
					pivot.rotation.set(1.5, 0, 1);

					// Update the object's position based on mouse movement
					function calculateTargetPosition(event) {
						
						// Calculate normalized device coordinates
						var mouseX = (event.clientX / window.innerWidth) * 2 - 1;
						var mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
						
						// Convert mouse coordinates to world coordinates
						var vector = new THREE.Vector3(mouseX, mouseY, 0.5);
						vector.unproject(camera);
						
						// Calculate the direction and distance of the object from the camera
						var direction = vector.sub(camera.position).normalize();
						var distance = -camera.position.z / direction.z;
						
						// Calculate the target position
						var targetPosition = camera.position.clone().add(direction.multiplyScalar(distance));

						// Adjust the target position to move less
						var moveFactor = 0.15;
						targetPosition.multiplyScalar(moveFactor);
						
						return targetPosition;
					}

					// Update the object's orientation to look at the target position
					function updateObjectOrientation(event) {
						var targetPosition = calculateTargetPosition(event);
		
						gsap.to(mesh.rotation, {
							x: targetPosition.x,
							y: targetPosition.y,
							z: targetPosition.y,
							duration: 5,
							ease: 'power2.out'
						});
					}

					// Add event listener for mouse movement
					window.addEventListener('mousemove', updateObjectOrientation, false);

				} else {
					pivot.rotation.set(1.65, 0, .85);
				}

				// Add an event listener to the window's resize event
				if ($(window).width() > 993) {
					window.addEventListener('resize', onWindowResize);
					
					function onWindowResize() {

						// Update the renderer size
						renderer.setSize(window.innerWidth, window.innerHeight);

						// Update the camera aspect ratio
						camera.aspect = window.innerWidth / window.innerHeight;
						camera.updateProjectionMatrix();
					}
				}

				// scroll effects
				if ($(window).width() > 993) {
					gsap.to(pivot.position, {
						y: .15,
						x: 0,
						ease: 'power2.out',
						scrollTrigger: {
							trigger: '#home-banner',
							start: 'top top',
							end: '+=' + vh(100),
							scrub: 3
						}
					})

					gsap.to(pivot.scale, {
						x: .65,
						y: .65,
						z: .65,
						ease: 'power2.out',
						scrollTrigger: {
							trigger: '#home-banner',
							start: 'top top',
							end: '+=' + vh(300),
							scrub: 3
						}
					})

					gsap.to(material.color, {
						r: 1,
						g: 1,
						b: 1,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: 3
						},
						onUpdate: () => {
							material.needsUpdate = true;
						},
					})

					gsap.to(material, {
						opacity: .65,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: 3
						},
						onUpdate: () => {
							material.needsUpdate = true;
						},
					})

					gsap.to(light.color, {
						r: .001,
						g: .001,
						b: .001,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: 3
						}
					})

					gsap.to(light2.color, {
						r: 2,
						g: 2,
						b: 2,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: 3
						}
					})

				} else {
					gsap.to(pivot.position, {
						y: 0,
						x: 1.5,
						ease: 'power2.out',
						scrollTrigger: {
							trigger: '#home-banner',
							start: 'top top',
							end: '+=' + vh(100),
							scrub: 3
						}
					})

					gsap.to(pivot.scale, {
						x: .65,
						y: .65,
						z: .65,
						ease: 'power2.out',
						scrollTrigger: {
							trigger: '#home-banner',
							start: 'top top',
							end: '+=' + vh(300),
							scrub: true
						}
					})
		
					gsap.to(pivot.rotation, {
						z: 7,
						scrollTrigger: {
							trigger: '#home-banner',
							start: 'top top',
							end: '+=' + vh(300),
							scrub: 3
						}
					})

					gsap.to(material.color, {
						r: 1,
						g: 1,
						b: 1,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: true
						},
						onUpdate: () => {
							material.needsUpdate = true;
						},
					})

					gsap.to(material, {
						opacity: .5,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: true
						},
						onUpdate: () => {
							material.needsUpdate = true;
						},
					})

					gsap.to(light.color, {
						r: .001,
						g: .001,
						b: .001,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: true
						}
					})

					gsap.to(light2.color, {
						r: 2,
						g: 2,
						b: 2,
						scrollTrigger: {
							trigger: '#main-content',
							start: vh(100) + ' top',
							end: '+=' + vh(100),
							scrub: true
						}
					})
				}

				gsap.to(pivot.rotation, {
					z: 7,
					scrollTrigger: {
						trigger: '#home-banner',
						start: 'top top',
						end: '+=' + vh(300),
						scrub: 3
					}
				})

				gsap.to(particle.position, {
					y: 500,
					scrollTrigger: {
						trigger: '#main-content',
						start: '1 top',
						end: '+=' + vh(300),
						scrub: 3
					}
				})

				// render the scene
				function animate() {
					requestAnimationFrame(animate);
					particle.rotation.x += 0.0015;
					renderer.render(scene, camera); 
				}

				animate();

				// listen to when the opening animations are finished and execute the functions below
				document.addEventListener('playOpening', function() {

					gsap.from(particle.position, {
						y: -200,
						duration: 6,
						ease: Power3.easeOut,
					})

					const play = gsap.timeline();

					play.set('#top-menu', {
						autoAlpha: 0,
						y: -50
					})

					play.set('#floating-menu', {
						autoAlpha: 0,
						y: -50
					})

					play.set('#home-banner .arrow', {
						autoAlpha: 0,
						y: 50
					})

					play.set('#home-banner .bottom', {
						autoAlpha: 0,
						y: 50
					})

					play.set('#home-banner .texts', {
						autoAlpha: 0,
						y: 50
					})

					play.from(object.position, {
						x: -10,
						y: -10,
						z: 75,
						duration: 3,
						ease: 'back.out(.5)'
					})

					play.from(object.rotation, {
						z: -20,
						duration: 5,
					}, '-=3')

					play.to('#top-menu', {
						autoAlpha: 1,
						y: 0,
						duration: 1
					}, '-=4')

					play.to('#floating-menu', {
						autoAlpha: 1,
						y: 0,
						duration: 1
					}, '-=4')

					play.to('#home-banner .arrow', {
						autoAlpha: 1,
						y: 0,
						duration: 1
					}, '-=4')

					play.to('#home-banner .bottom', {
						autoAlpha: 1,
						y: 0,
						duration: 1
					}, '-=4')

					play.to('#home-banner .texts', {
						autoAlpha: 1,
						y: 0,
						duration: 1
					}, '-=4')

				});
			});

			// create the scroll animations
			ScrollTrigger.create({
				pin: '#home-banner',
				anticipatePin: 1,
				trigger: '#home-banner',
				start: '1 top',
				end: '+=' + vh(100),
				scrub: true
			})

			ScrollTrigger.create({
				pin: '#three-js',
				anticipatePin: 1,
				trigger: '#home-banner',
				start: '1 top',
				end: '+=' + vh(201),
				scrub: true
			})

			gsap.to('#home-banner .fade-out', {
				autoAlpha: 0,
				scrollTrigger: {
					trigger: '#home-banner',
					start: 'top top',
					end: '+=' + vh(75),
					scrub: 2
				}
			})

			gsap.to('#three-js img', {
				autoAlpha: 0,
				scrollTrigger: {
					trigger: '#home-banner',
					start: 'top top',
					end: '+=' + vh(125),
					scrub: 2
				}
			})

			gsap.to('#three-js', {
				backgroundColor: '#f8f8f8',
				scrollTrigger: {
					trigger: '#main-content',
					start: vh(100) + ' top',
					end: '+=' + vh(100),
					scrub: true
				}
			})

			gsap.to('#home-banner', {
				color: '#000000',
				scrollTrigger: {
					trigger: '#main-content',
					start: vh(100) + ' top',
					end: '+=' + vh(100),
					scrub: 2
				}
			})
		}
	})
}

// init projects section
function initProjects() {
	document.fonts.ready.then(() => {
		if(select('.projects-grid')) {

			function moveCircle(e) {
				gsap.to($('.projects-grid .hover-section'), 3, {
					x: e.pageX - $('.projects-grid').offset().left,
					y: e.pageY - $('.projects-grid').offset().top,
					ease: 'circ.out'
				})
			}

			$('.projects-grid').on('mousemove', moveCircle)
			$('.projects-grid').on('mousewheel', moveCircle)

			$('.projects-grid').on('mouseenter', function(){
				gsap.to($('.projects-grid .hover-section .wrapper'), .375, {
					opacity: 1,
					ease: 'circ.out'
				})
			})

			$('.projects-grid').on('mouseleave', function(){
				gsap.to($('.projects-grid .hover-section .wrapper'), .375, {
					opacity: 0,
					ease: 'circ.out'
				})
			})

			$('.projects-grid ul').on('mouseenter', 'li', function() {
				var index = $(this).index() + 1;

				gsap.to('.projects-grid .hover-section .wrapper', {
					y: index === 1 ? 0 : '-' + (55 * (index - 1)) + 'vh',
					ease: 'power2.out'
				})
			})
		}
	})
}

// init all function inside the reel section
function initReel() {
	document.fonts.ready.then(() => {
    	if (selectId('reel')) {
		
			// mount video player (on the popup)
			var player = videojs('video-reel')
			
			// scroll trigger pin video section
			const gsapSection = $('#reel')
			const gsapInnerSection = $('#reel .inner')
			const gsapTextLeft = $('#reel .text-left')
			const gsapTextRight = $('#reel .text-right')
			const gsapVideo = $('#reel video')

			ScrollTrigger.create({
				pin: gsapInnerSection,
				anticipatePin: 1,
				trigger: gsapSection,
				start: 'top top',
				end: '+=' + vh(225),
				scrub: true
			})

			gsap.from(gsapTextLeft, {
				x: '-20vw',
				scrollTrigger: {
					trigger: gsapSection,
					start: 'top top',
					end: '+=' + vh(200),
					scrub: 2
				}
			})

			gsap.from(gsapTextRight, {
				x: '20vw',
				scrollTrigger: {
					trigger: gsapSection,
					start: 'top top',
					end: '+=' + vh(200),
					scrub: 2
				}
			})

			gsap.from(gsapVideo, {
				scale: .25,
				autoAlpha: .5,
				scrollTrigger: {
					trigger: gsapSection,
					start: 'top top',
					end: '+=' + vh(200),
					scrub: 2
				}
			})

			// open / close big reel vieo on click
			$('#reel .inner').click(function(){
				$('body').addClass('popup-open')
				$('#overlay-video').fadeIn(600)

				setTimeout(function(){
					player.play()
				}, 700)
			})

			$('#overlay-video .close-popup').click(function(){
				$('body').removeClass('popup-open')
				player.pause()
				$('#overlay-video').fadeOut()
			})

			$(document).keyup(function(e) {
				if(e.key === 'Escape') {
					player.pause()
					$('#overlay-video').fadeOut()
				}
			})
	
		}
	})
}

function initCountries() {
	document.fonts.ready.then(() => {
		if (selectId('countries')) {
			gsap.to('#countries .marquee', {
				x: '-102.5%',
				duration: 20,
				ease: 'linear',
				repeat: -1
			})
		}
	})
}

// init all function inside the services section
function initServices() {
	document.fonts.ready.then(() => {
		if (selectId('services')) {

			// invert the colors of the section on scroll
			gsap.to('#services', {
				filter: 'invert(0)',
				scrollTrigger: {
					trigger: '#services',
					start: 'top 75%',
					end: 'top 20%',
					scrub: 2
				}
			})

			// split the services words into chars
			const services = $('#services .services-list li')

			$.each(services, function() {

				var link = $(this).children('a')

				var letter = link.children('.name').children('span')

				var hoverStagger = gsap.timeline({ paused: true })
					.to(letter, {
						opacity: 1,
						y: -160 + '%',
						duration: 3,
						stagger: 0.025,
						ease: 'elastic.out(1, 0.3)'
					})
				
				link.on('mouseenter', function() {
					hoverStagger.seek(0)
					hoverStagger.play()
				})
			})

		}
	})
}

// here goes all the scroll related animations
function scrollTriggerAnimations() {
	document.fonts.ready.then(() => {
	
		// title fill animation
		if(select('.fill-title')) {
			const split = new SplitText('.fill-title', { type: 'lines' })

			split.lines.forEach((target) => {
				gsap.to(target, {
					backgroundPositionX: 0,
					ease: 'none',
					scrollTrigger: {
						trigger: target,
						scrub: 3,
						start: 'top 75%',
						end: 'bottom 25%'
					}
				})
			})
		}

		// reveal text animation
		if(select('.reveal-text')) {
			const texts = selectAll('.reveal-text')
		
			texts.forEach(text => {
				
				// reset if needed
				if(text.anim) {
					text.anim.progress(1).kill()
					text.split.revert()
				}

				text.split = new SplitText(text, { 
					type: 'lines, words, chars',
					linesClass: 'split-line'
				})

				// set up the anim
				text.anim = gsap.from(text.split.chars, {
					scrollTrigger: {
						trigger: text,
						toggleActions: 'restart pause resume reverse',
						start: 'top 75%'
					},
					duration: 0.5, 
					ease: 'circ.out', 
					y: 100 + '%', 
					stagger: 0.01
				})
			})
		}

		// image reveal animation
		if(selectAll('.image-reveal')) {
			const imageReveal = $('.image-reveal')

			$.each(imageReveal, function() {

				const reveal = $(this).children('.reveal')
				const image = $(this).children('img')

				const timeline = gsap.timeline({
					paused: true,
				})

				timeline.set(image, {
					scale: 1.5
				})

				timeline.to(reveal, {
					width: '100%',
					ease: Power4.easeInOut,
					duration: .75,
				})

				timeline.to(reveal, {
					x: '100%',
					ease: Power4.easeIn,
					duration: 1,
				})

				timeline.to(image, {
					visibility: 'visible',
					scale: 1,
					duration: 2.5,
					ease: Power4.easeOut,
				}, '=-1')

				ScrollTrigger.create({
					trigger: imageReveal,
					start: '0% 120%',
					end: '100% -20%',
					onEnter: () => timeline.play(),
					onLeaveBack: () => timeline.reverse()
				})

			})
		}

	})
}

// init all click, mouseover and keyup functions
function initClickAndKeyFunctions() {

	// prevent barba from double clicking buttons more than once
	// https://stackoverflow.com/a/36794629/4658966
	function isDoubleClicked(e) {
		if (e.data('isclicked')) return true
		e.data('isclicked', true)
		setTimeout(function () {
			e.removeData('isclicked')
		}, 300);
	
		return false
	}

	// make anchor links scroll smoothy
	$('.sliding-link').click(function(e) {
		if (isDoubleClicked($(this))) return
		e.preventDefault()
		var aid = $(this).attr('href')
		$('html, body').animate({ scrollTop: $(aid).offset().top }, 2000)
	})

	// correct label click
	$('label').click(function(e){
		e.stopImmediatePropagation()
	})

	// open / close fs menu
    $('.open-fs, .fs-bg, #fs-menu a').click(function(){
		if (isDoubleClicked($(this))) return
        $('.open-fs').toggleClass('active')
        $('body').toggleClass('fs-menu-open')
    })

    $(document).keyup(function(e) {
        if(e.key === 'Escape') {
            $('body').removeClass('fs-menu-open popup-open')
            $('.open-fs').removeClass('active')
            $('#overlay').fadeOut()
        }
    })

	// faq open / close
	$('.faq-question .title').click(function(){
		if (isDoubleClicked($(this))) return
		$(this).parent('.faq-question').toggleClass('active')
		$(this).siblings('.desc').slideToggle()
		setTimeout(function(){
			ScrollTrigger.refresh()
		}, 450)
	})

	// show / hide password
	$('.show-hide-pass').click(function(){
		if (isDoubleClicked($(this))) return
		var that = $(this)
		that.toggleClass('active')

		setTimeout(function() {
			if (that.hasClass('active')){
				that.siblings('input').attr('type','text')
			} else {
				that.siblings('input').attr('type','password')
			}
		}, 30)
	})

	// close cookies popup
	$('#cookies-popup button').click(function() {
		if (isDoubleClicked($(this))) return
		$('#cookies-popup').fadeOut()
	})

    // magnet links
    if ($(window).width() > 993) {
        const links = selectAll('.magnet')
        const animateLink = function(e){
            const link = this.querySelector('span')
            const { offsetX: x, offsetY: y } = e
            const { offsetWidth: width, offsetHeight: height } = this

            intensity = 50
            xMove = x / width * (intensity * 2) - intensity
            yMove = y / height * (intensity * 2) - intensity
            link.style.transform = 'translate(' + xMove + 'px,' + yMove + 'px)'

            if(e.type == 'mouseleave') link.style.transform = ''
        }

        links.forEach(link => {
            link.addEventListener('mousemove', animateLink)
            link.addEventListener('mouseleave', animateLink)
        })
    }

	// follow mouse object
	if($('.follow-mouse-object').length) {
		document.fonts.ready.then(() => {
			gsap.utils.toArray('.follow-mouse-object').forEach(item => {

				const parent = item.closest('.follow-mouse-section')

				const middleX = item.offsetWidth / 2
				const middleY = item.offsetHeight / 2 

				gsap.set(item, {
					top: '-' + middleX,
					left: '-' + middleY
				})

				function moveCircle(e) {
					gsap.to(item, 1, {
						x: e.pageX - $(parent).offset().left,
						y: e.pageY - $(parent).offset().top,
						ease: Expo.easeOut
					})
				}

				$(parent).on('mousemove', moveCircle)
				$(parent).on('mousewheel', moveCircle)

				$(parent).on('mouseenter', function(){
					gsap.to(item, {
						scale: 1,
						opacity: 1,
						duration: .5,
						ease: 'circ.out'
					})
				})

				$(parent).on('mouseleave', function(){
					gsap.to(item, {
						scale: 0,
						opacity: 0,
						duration: .5,
						ease: 'circ.out'
					})
				})

			})
		})
	}
}

// page transition in
function pageTransitionIn() {

	var transitionIn = gsap.timeline()

	transitionIn.set('.page-transition .logo svg', {
		yPercent: 50
	})

	transitionIn.set('#bottom-to-top stop', {
		attr: { offset: '100%' }
	})

	transitionIn.set('.page-transition .bg', { 
		top: '100%' 
	})

	transitionIn.set('html', { 
		cursor: 'wait',
		pointerEvents: 'none'
	})

	if ($(window).width() > 993) { 
		transitionIn.set('.page-transition .rounded-div-wrap.bottom', { 
		  	height: '10vh',
		})
	} else {
		transitionIn.set('.page-transition .rounded-div-wrap.bottom', { 
		  	height: '5vh',
		})
	}
	
	transitionIn.to('.page-transition .bg', {
		duration: .5,
		top: '0%',
		ease: 'Power4.easeIn'
	})
	
	if ($(window).width() > 993) { 
		transitionIn.to('.page-transition .rounded-div-wrap.top', {
			duration: .4,
			height: '10vh',
			ease: 'Power4.easeIn'
		},'=-.5')
	} else {
		transitionIn.to('.page-transition .rounded-div-wrap.top', {
			duration: .4,
			height: '10vh',
			ease: 'Power4.easeIn'
		},'=-.5')
	}
}

// page transition out
function pageTransitionOut() {
	var transitionOut = gsap.timeline()
	var onceIn = gsap.timeline({ paused: true })

	// animate all elements UP
	if ($(window).width() > 993) { 
		onceIn.set('main .once-in', {
			y: '50vh',
		})
	} else {
		onceIn.set('main .once-in', {
			y: '30vh'
		})
	}

	onceIn.to('main .once-in', {
		duration: 1,
		y: '0vh',
		stagger: .05,
		ease: 'Expo.easeOut',
		delay: .25,
		clearProps: 'true'
	})

	transitionOut.set('.page-transition .rounded-div-wrap.top', {
		height: '0vh'
	})

	transitionOut.to('.page-transition .logo svg', {
		yPercent: 0,
		opacity: 1,
		duration: .6,
		ease: 'Power2.easeInOut'
	})

	transitionOut.to('#bottom-to-top stop', {
		attr: { offset: '0%' },
		duration: 1,
		ease: 'Power2.easeInOut',
		onComplete: () => {
			onceIn.play()
		}
	})

	transitionOut.to('.page-transition .bg', {
		duration: .8,
		top: '-100%',
		ease: 'Power3.easeInOut'
	})

	transitionOut.to('.page-transition .logo svg', {
		duration: .6,
		yPercent: -50,
		opacity: 0,
		ease: 'Power2.easeInOut'
	}, '-=.8')

	transitionOut.to('.page-transition .rounded-div-wrap.bottom', {
		duration: .85,
		height: '0',
		ease: 'Power3.easeInOut'
	}, '-=.8')

	transitionOut.to('html', { 
		cursor: 'auto',
		pointerEvents: 'all',
		duration: 0,
		onComplete: () => {
			ScrollTrigger.refresh()
		}
	})

	if ($(window).width() > 993) { 
		transitionOut.set('.page-transition .rounded-div-wrap.bottom', {
			height: '10vh'
		})
	} else {
		transitionOut.set('.page-transition .rounded-div-wrap.bottom', {
			height: '5vh'
		})
	}

	transitionOut.set('.page-transition .bg', { 
		top: '100%'
	})
}

// page transition once
function pageTransitionOnce() {

	var transitionOnce = gsap.timeline()

	transitionOnce.set('.page-transition .logo svg', {
		yPercent: 50
	})

	transitionOnce.set('html', { 
		cursor: 'wait',
		pointerEvents: 'none'
	})

	transitionOnce.call(function(){
		$('#opening-animation').remove()
	})

	if ($(window).width() > 993) { 
		transitionOnce.set('main .once-in', {
		  	y: '50vh'
		})
	} else {
		transitionOnce.set('main .once-in', {
		  	y: '30vh'
		})
	}

	if ($(window).width() > 993) { 
		transitionOnce.set('.page-transition .rounded-div-wrap.bottom', { 
		  	height: '10vh',
		})
	} else {
		transitionOnce.set('.page-transition .rounded-div-wrap.bottom', { 
		  	height: '5vh',
		})
	}
	
	if ($(window).width() > 993) { 
		transitionOnce.to('.page-transition .rounded-div-wrap.top', {
			duration: .4,
			height: '10vh',
			ease: 'Power4.easeIn'
		},'=-.5')
	} else {
		transitionOnce.to('.page-transition .rounded-div-wrap.top', {
			duration: .4,
			height: '10vh',
			ease: 'Power4.easeIn'
		},'=-.5')
	}

	transitionOnce.to('.page-transition .logo svg', {
		yPercent: 0,
		opacity: 1,
		duration: .6,
		ease: 'Power2.easeInOut'
	}, '=-.1')

	transitionOnce.call(function(){
		document.dispatchEvent(new Event('playOpening'))
		setTimeout( window.scrollTo(0, 0), 400)
	})

	transitionOnce.to('#bottom-to-top stop', {
		attr: { offset: '0%' },
		duration: 1,
		ease: 'Power2.easeInOut'
	}, '=-.2')

	transitionOnce.set('.page-transition .rounded-div-wrap.top', {
		height: '0vh'
	})

	transitionOnce.to('.page-transition .bg', {
		duration: .8,
		top: '-100%',
		ease: 'Power3.easeInOut'
	}, '=-.2')

	transitionOnce.to('.page-transition .logo svg', {
		duration: .6,
		yPercent: -50,
		opacity: 0,
		ease: 'Power2.easeInOut'
	}, '=-.8')

	transitionOnce.to('.page-transition .rounded-div-wrap.bottom', {
		duration: .85,
		height: '0',
		ease: 'Power3.easeInOut'
	}, '=-.6')

	transitionOnce.to('html', { 
		cursor: 'auto',
		pointerEvents: 'all',
		duration: 0
	}, '=-0.6')

	if ($(window).width() > 993) { 
		transitionOnce.set('.page-transition .rounded-div-wrap.bottom', {
			height: '10vh'
		})
	} else {
		transitionOnce.set('.page-transition .rounded-div-wrap.bottom', {
			height: '5vh'
		})
	}

	transitionOnce.set('.page-transition .bg', { 
		top: '100%' 
	})

	transitionOnce.to('main .once-in', {
		duration: 1,
		y: '0vh',
		stagger: .05,
		ease: 'Expo.easeOut',
		clearProps: 'true'
	}, '=-.9')
}

// delay function (to prevent double clicks on barba.js)
function delay(n) {
	n = n || 2000
	return new Promise((done) => {
		setTimeout(() => {
			done()
		}, n)
	})
}

// init scroll smoother
function initScrollSmoother() {
	if (ScrollTrigger.isTouch !== 1) {

		// create scroll smoother
		smoother = ScrollSmoother.create({
			wrapper: '#smooth-content',
			content: '#smooth-content .main-wrap',
			smooth: 2,
			speed: .75,
			smoothTouch: .1,
			effects: true,
			normalizeScroll: true
		})

		// parallax effect
		smoother.effects('.parallax-img', {
			speed: 'auto'
		})

		// when inside project-inner, wait for the top image animation to finish and then enable page scroll
		if($('#main-content').hasClass('project-inner')) {
			smoother.paused(true)

			document.addEventListener('unpause', function() {
				smoother.paused(false)
				ScrollTrigger.refresh()
			})
		}

	} else {

		if($('#main-content').hasClass('project-inner')) {
			gsap.set('body', {
				overflow: 'hidden',
			})

			document.addEventListener('unpause', function() {
				gsap.to('body', {
					overflow: 'visible',
				})

				ScrollTrigger.refresh()
			})
		}
	}
}

// fire all scripts on page load
function initScript() {
	validateForms()
	initLazyLoad()
	initHomeBanner()
	initProjects()
    initReel()
	initServices()
	scrollTriggerAnimations()
	initClickAndKeyFunctions()
	initCountries()
	updateMenu()
	initScrollSmoother()
}

// disable console warnings and show the copyright message
function initCopyright() {
	//console.clear()
	const message = ' Masterfully crafted by The Skyline Agency 🚀 www.theskylineagency.com '
	const style = 'color: #f8f8f8; font-size: 12px; font-weight: bold; background-color: #0d0e13; padding: 8px'
	console.log(`%c${message}`, style)
}

// barba
function initBarba() {
	barba.init({
		sync: true,
		timeout: 7000,
		debug: true,
		preventRunning: true,
		preventClick: true,
		transitions: [{
			name: 'general',
			once() {
				initScript()
				pageTransitionOnce()
				initCopyright()
			},

			before() {
				if (ScrollTrigger.isTouch !== 1) {
					smoother.paused(true)
				}
			},

			async leave(data) {
				pageTransitionIn(data.current)
				await delay(600)
				data.current.container.remove()
			},

			async beforeEnter() {
				ScrollTrigger.killAll()
			},

			async enter(data) {
				pageTransitionOut(data.next)
				setTimeout(window.scrollTo(0, 0), 300)
				initScript()
			},
		}],
		views: [
			{
				namespace: 'about',
				afterEnter() {

					// create the scene
					const scene = new THREE.Scene()

					// create a renderer
					const renderer = new THREE.WebGLRenderer({
						antialias: true,
						alpha: true
					})

					// set the size of the renderer and append it to an element
					const topSection = selectId('top')
					renderer.setSize( window.innerWidth, topSection.offsetHeight)
					renderer.setPixelRatio( window.devicePixelRatio * 0.5 )
					selectId('particles').appendChild(renderer.domElement)

					// create the camera
					const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / topSection.offsetHeight, 0.1, 1000 )

					// add particles
					var particle
					particle = new THREE.Object3D()
					var geometry = new THREE.TetrahedronGeometry(.25, 1)

					var particleMaterial = new THREE.MeshPhongMaterial({
						color: 0xf8f8f8
					})

					if ($(window).width() > 993) {
						for (var i = 0; i < 2000; i++) {
							var particleMesh = new THREE.Mesh(geometry, particleMaterial)
							particleMesh.position.set(Math.random() - .5, Math.random() - .5, Math.random() - .5).normalize()
							particleMesh.position.multiplyScalar(90 + (Math.random() * 1000))
							particleMesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2)
							particle.add(particleMesh)
						}
					} else {
						for (var i = 0; i < 500; i++) {
							var particleMesh = new THREE.Mesh(geometry, particleMaterial)
							particleMesh.position.set(Math.random() - .5, Math.random() - .5, Math.random() - .5).normalize()
							particleMesh.position.multiplyScalar(90 + (Math.random() * 500))
							particleMesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2)
							particle.add(particleMesh)
						}
					}

					scene.add(particle)

					// Add an event listener to the window's resize event
					if ($(window).width() > 993) {
						window.addEventListener('resize', onWindowResize)
						
						function onWindowResize() {

							// Update the renderer size
							renderer.setSize(window.innerWidth, topSection.offsetHeight)

							// Update the camera aspect ratio
							camera.aspect = window.innerWidth / topSection.offsetHeight
							camera.updateProjectionMatrix();
						}
					}

					gsap.to(particle.position, {
						y: 500,
						scrollTrigger: {
							trigger: '#main-content',
							start: '1 top',
							end: '+=' + vh(300),
							scrub: 3
						}
					})

					// render the scene
					function animate() {
						requestAnimationFrame(animate)
						particle.rotation.x += 0.0015
						renderer.render(scene, camera);
					}

					animate();
			
					gsap.to('#top .scroll', {
						autoAlpha: 0,
						scrollTrigger: {
							trigger: '#top',
							start: 'top top',
							end: '+=' + vh(20),
							scrub: 3
						}
					})

					gsap.to('#top .parallax .nova', {
						marginTop: '10vh',
						duration: 10,
						repeat: -1,
						yoyo: true,
						ease: 'power2.inOut'
					})

					gsap.to('#top .parallax .bg', {
						opacity: 0,
						scrollTrigger: {
							trigger: '#top',
							start: 'top top',
							end: 'bottom top',
							scrub: 3
						}
					})
			
					if ($(window).width() > 993) {
						gsap.to('#top .parallax .nova', {
							y: '55vh',
							scrollTrigger: {
								trigger: '#top',
								start: 'top top',
								end: 'bottom top',
								scrub: 3
							}
						})
					} else {
						gsap.to('#top .parallax .nova', {
							y: '35vh',
							scale: .75,
							scrollTrigger: {
								trigger: '#top',
								start: 'top top',
								end: 'bottom top',
								scrub: 3
							}
						})
					}

					gsap.to('#about .photo-01', {
						y: '-20%',
						scrollTrigger: {
							trigger: '#about .photo-01',
							start: 'top bottom',
							end: '+=' + vh(150),
							scrub: 2
						}
					})

					gsap.to('#about .photo-03', {
						y: '30%',
						scrollTrigger: {
							trigger: '#about .photo-03',
							start: 'top bottom',
							end: '+=' + vh(150),
							scrub: 2
						}
					})
				}
			}, {
				namespace: 'projects',
				afterEnter() {
					$('#top-menu').addClass('white')
				},
				afterLeave() {
					$('#top-menu').removeClass('white')
				}
			},  {
				namespace: 'project-inner',
				afterEnter() {
					$('#footer').remove()

					if ($(window).width() > 993) {
						gsap.to('#top .bg', {
							y: '40%',
							scrollTrigger: {
								trigger: '#top',
								start: '1 0',
								endTrigger: '#middle',
								end: 'top top',
								scrub: true
							}
						})

						gsap.to('#top .bg', {
							autoAlpha: .25,
							scrollTrigger: {
								trigger: '#middle',
								start: '0 100%',
								end: '0 -10%',
								scrub: 2
							}
						})
					} else {
						gsap.set('#top .bg', {
							height: '100lvh',
						})
				
						gsap.to('#top .bg', {
							scale: 1.175,
							autoAlpha: .25,
							scrollTrigger: {
								pin: '#top .bg',
								anticipatePin: 1,
								trigger: '#top',
								start: '1 top',
								endTrigger: '#middle',
								end: 'top bottom',
								scrub: true
							}
						})	
					}

					gsap.from('#top .bg img', {
						scale: 1.175,
						autoAlpha: 0,
						duration: 2.5,
						delay: 1,
						onComplete: () => {
							document.dispatchEvent(new Event('unpause'))
						}
					})

					if(select('.expanding-grid')) {

						let mainEl = $('.expanding-grid .elements .position-00 .main-element')
						let mainElChild = $('.expanding-grid .elements .position-00 .main-element > *')
						let el00 = $('.expanding-grid .elements .position-00')
						let el01 = $('.expanding-grid .elements .position-01')
						let el02 = $('.expanding-grid .elements .position-02')
						let el03 = $('.expanding-grid .elements .position-03')
						let el04 = $('.expanding-grid .elements .position-04')
						let el05 = $('.expanding-grid .elements .position-05')
						let el06 = $('.expanding-grid .elements .position-06')
				
						ScrollTrigger.create({
							trigger: '.expanding-grid',
							pin: true,
							anticipatePin: 1,
							start: 'top top',
							end: '+=' + vh(200)
						})
				
						gsap.to(el00, {
							scale: 4,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(mainEl, {
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})

						gsap.from(mainElChild, {
							scale: 1.5,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el01, {
							scale: 5,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el02, {
							scale: 6,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el03, {
							scale: 8,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el04, {
							scale: 5,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el05, {
							scale: 6,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
						gsap.to(el06, {
							scale: 9,
							scrollTrigger: {
								trigger: '.expanding-grid',
								start: 'top top',
								end: '+=' + vh(200),
								scrub: () => {
									if (ScrollTrigger.isTouch !== 1) {
										3
									} else {
										true
									}
								}
							}
						})
				
					}

					if (select('.marquee')) {
						gsap.fromTo('.marquee .left', {
							x: 0
						}, {
							x: '-15vw',
							scrollTrigger: {
								trigger: '.marquee',
								start: '-10% 110%',
								end: '110% -10%',
								scrub: true
							}
						})

						gsap.fromTo('.marquee .right', {
							x: '-15vw'
						}, {
							x: '0',
							scrollTrigger: {
								trigger: '.marquee',
								start: '-10% 110%',
								end: '110% -10%',
								scrub: true
							}
						})
					}

					// slider
					if(select('.slider')) {
						const pageSlider = selectAll('.swiper-container')
						
						for( i=0; i< pageSlider.length; i++ ) {
							pageSlider[i].classList.add('swiper-container-' + i)

							var slider = new Swiper('.swiper-container-' + i, {
								slidesPerView: 'auto',
								loop: false,
								simulateTouch: true,
								allowTouchMove: true,
								autoHeight: false,
								calculateHeight: true,
								spaceBetween: 15,
								speed: 600,
								freeMode: true,
								mousewheel: {  
									forceToAxis: true
								},
								breakpoints: {
									575: {
										spaceBetween: 25
									}, 768: {
										spaceBetween: 40
									}, 1201: {
										spaceBetween: 50
									}
								},
								on: {
									touchStart(){
										$('.swiper-container').addClass('is-dragging')
									}, touchEnd(){
										$('.swiper-container').removeClass('is-dragging')
									}
								},
								scrollbar: {
									el: '.swiper-container-' + i + ' .scroll-bar',
									draggable: true
								}
							})
						}
					}

					// create the next project animation (at the bottom of the page)
					var link = $('#next-project .link')
					var nextPageUrl = link.attr('href')

					ScrollTrigger.create({
						trigger: '#next-project',
						pin: true,
						anticipatePin: 1,
						start: 'top top',
						end: '+=' + vh(150)
					})

					ScrollTrigger.create({
						trigger: '#next-project',
						start: 'top bottom',
						onEnter: () => {
							barba.prefetch(nextPageUrl)
						}
					})

					ScrollTrigger.create({
						trigger: '#next-project',
						start: 'top top',
						end: '+=' + vh(145),
						beforeLeave: (data) => {
							barba.history.add(data.current.url)
						},
						onLeave: () => {
							nextTitle.anim = gsap.to(nextTitle.split.chars, {
								duration: 1, 
								ease: 'circ.out',
								y: '-100%',
								autoAlpha: 0, 
								stagger: 0.01
							})

							gsap.to('#next-project .main-image img', {
								y: '-105%',
								duration: 1,
								ease: 'power2.out',
							})

							gsap.to('#next-project .next', {
								autoAlpha: 0,
								y: '-200%'
							})

							gsap.to('#next-project .more', {
								autoAlpha: 0,
								y: '-200%'
							})

							setTimeout(function(){
								barba.go(nextPageUrl)
							}, 300)
						}
					})
			
					gsap.to('#next-project .loader .loading-time', {
						width: '100%',
						scrollTrigger: {
							trigger: '#next-project',
							start: 'top top',
							end: '+=' + vh(150),
							scrub: () => {
								if (ScrollTrigger.isTouch !== 1) {
									3
								} else {
									true
								}
							}
						}
					})

					gsap.from('#next-project .bg img', {
						autoAlpha: 0,
						scale: 1.175,
						scrollTrigger: {
							trigger: '#next-project',
							start: 'top bottom',
							end: '+=' + vh(200),
							scrub: () => {
								if (ScrollTrigger.isTouch !== 1) {
									3
								} else {
									true
								}
							}
						}
					})

					gsap.from('#next-project .main-image img', {
						y: '100%',
						scrollTrigger: {
							trigger: '#next-project',
							start: 'top top',
							end: '+=' + vh(150),
							scrub: () => {
								if (ScrollTrigger.isTouch !== 1) {
									3
								} else {
									true
								}
							}
						}
					})

					const nextTitle = $('#next-project .title')

					if(nextTitle.anim) {
						nextTitle.anim.progress(1).kill()
						nextTitle.split.revert()
					}
		
					nextTitle.split = new SplitText(nextTitle, { 
						type: 'lines, words, chars',
						linesClass: 'split-line'
					})
		
					nextTitle.anim = gsap.from(nextTitle.split.chars, {
						scrollTrigger: {
							trigger: nextTitle,
							toggleActions: 'restart pause resume reverse',
							start: 'top 80%'
						},
						duration: 1, 
						ease: 'circ.out', 
						y: 100 + '%',
						autoAlpha: 0, 
						stagger: 0.02
					})
				}
			}, {
				namespace: 'contact',
				afterEnter() {
					$('#top-menu').addClass('white')
				},
				afterLeave() {
					$('#top-menu').removeClass('white')
				}
			}, {
				namespace: '404',
				afterEnter() {
					
					const bouncing404 = selectId('bounce')

					// Set the initial position and velocity
					let x = 0
					let y = 0
					let velocityX = 2
					let velocityY = 2

					// Animation function
					function animate() {
						// Update the position based on velocity
						x += velocityX
						y += velocityY

						// Check for collision with the edges of the window
						const windowWidth = window.innerWidth
						const windowHeight = window.innerHeight

						if (x + bouncing404.offsetWidth >= windowWidth || x <= 0) {
							// Reverse the horizontal direction
							velocityX *= -1
						}

						if (y + bouncing404.offsetHeight >= windowHeight || y <= 0) {
							// Reverse the vertical direction
							velocityY *= -1
						}

						// Apply the new position using GSAP
						gsap.set(bouncing404, { x: x, y: y })

						// Repeat the animation on the next frame
						requestAnimationFrame(animate)
					}

					// Start the animation
					animate()

				}
			}
		],

		// go to a custom 404 page if the user click on a link that return a 404 response status
		requestError: (trigger, action, url, response) => {
			if (action === 'click' && response.status && response.status === 404) {
				barba.go('404')
			}
			return false
		},
	})

	// reset initial scroll position to top
	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	
}

// init barba
initBarba()